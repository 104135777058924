body {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}

#content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 3rem 0;
    margin-left: 300px;
}

#header {
    position: relative;
    float: left;
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: row;
    padding: 0 2.5rem 1.5rem 2.5rem;
    margin-bottom: 20px;
}

#main {
    flex: 1;
    padding: 0 2.5rem;
    width: 100%;
}
#main .col {
    float: left;
    margin-right: 30px; /* col-gap */
    width: 230px; /* col-width */
}
#main .two-cols, #main .three-cols {
    max-width: 490px; /* 2 * col-width + col-gap */
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
}
@media (min-width: 1000px) {
    #main .three-cols {
        max-width: 750px; /* 3 * col-width + 2 * col-gap */
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 30px;
        -moz-column-gap: 30px;
        column-gap: 30px;
    }
}

#footer {
    margin: 1.5rem 2.5rem 0 1.5rem;
}

.pull-right {
    float: right;
}
@media (max-width: 1200px) {
    .pull-right {
        float: left;
    }
}

#header.header_references {
    margin-bottom: 32px;
}

.project-gallery {
    padding-top: 20px;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 14px;
    row-gap: 14px;
}
@media (min-width: 1500px) {
    .project-gallery {
        grid-auto-columns: 400px;
        grid-auto-rows: 400px;
    }
}
@media (max-width: 1500px) {
    .project-gallery {
        grid-auto-columns: 300px;
        grid-auto-rows: 300px;
    }
}
@media (max-width: 1100px) {
    .project-gallery {
        grid-auto-columns: 200px;
        grid-auto-rows: 200px;
    }
}
.project #header h1 {
    position: absolute;
    bottom: -0.34em;
    font-size: 56px;
    line-height: 1em;
    color: white;
    text-transform: uppercase;
}
.project-gallery img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.project-gallery img.row2 {
    -ms-grid-row-span: 2;
    grid-row: span 2;
}
.project-gallery img.col2 {
    -ms-grid-column-span: 2;
    grid-column: span 2;
}
.project-gallery img.col3 {
    -ms-grid-column-span: 3;
    grid-column: span 3;
}

/**
 * IE11 CSS hack
 * IE11 does not support gap between CSS grid items.
 * We use a black border and subtract the width from the paddings surrounding
 * the project gallery.
 */
_:-ms-fullscreen, :root .project-gallery img {
    border: 8px solid black;
}
_:-ms-fullscreen, :root #main {
    padding: 0 32px;
}
_:-ms-fullscreen, :root .project-gallery {
    padding-top: 12px;
    padding-bottom: 32px;
}
